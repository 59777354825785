---
title: Compass
layout: home
---

<script>
	import { Document } from '@ctm/icons/data';
	import { Applications, Home, GridView, Chat } from '@ctm/icons/interface';
	import { Compass } from '@ctm/icons/travel';
	import AppTile from '$lib/AppTile';
	import Figma from './Figma.svg?json';
	import Jira from './Jira.svg?json';

	console.debug('[/] Jira: %o', Jira);
</script>

Compass is our tool of choice when aligning the look and feel of CTM products.
As a designer or developer, you will find relevant guidelines within the three
sections that compass is divided into: Foundations, Components and Patterns.

---

<div class="grid">
  <AppTile href="/foundations" icon={Compass} label="Foundations" description="Colour, spacing, typography, etc." />
  <AppTile href="/components" icon={Applications} label="Components" description="Anatomy and usage guidelines" />
  <AppTile href="/patterns" icon={GridView} label="Patterns" description="Common patterns and layouts" />
  <!-- <AppTile href="/changelog" icon={Document} label="Changelog" /> -->
  <AppTile href="https://travelctm.atlassian.net/projects/COMPASS" icon={Jira} label="Jira project" description="View Compass project in Jira" />
  <AppTile href="https://www.figma.com/file/t20hU6laBaFJ8Hc9r2Vyhg/Compass-Design-Library-for-Figma" icon={Figma} label="Figma library" description="View Compass designs in Figma" />
</div>

<style>
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  .grid > :global(* + *) {
    margin-top: 0;
  }
</style>
