<script context="module">
	export { default as pre } from '../components/CodeBlock.svelte';
</script>

<script>
	import 'prism-themes/themes/prism-material-oceanic.css';
	import Container from '$lib/experimental/Container';
	import styles from './styles.module.css';

	export let title = '';

	/** @type {Element} */
	let content;
</script>

<svelte:head>
	{#if title}<title>{title}</title>{/if}
</svelte:head>

<Container as="article">
	<div bind:this={content} class={styles.content}>
		{#if title}<h1>{title}</h1>{/if}
		<slot />
	</div>
</Container>
